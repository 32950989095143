<template>
  <div>
  </div>
</template>

<script>
import router from '@/router/'
export default {
  name: 'redirect',
  mounted () {
    router.push({ name: 'Candidate' })
  }
}
</script>
